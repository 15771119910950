<script setup lang="ts">
import { useClipboard } from "@vueuse/core"
import CheckIcon from "@/assets/icons/check.svg?component"
import CopyIcon from "@/assets/icons/copy.svg?component"
import DownloadIcon from "@/assets/icons/download.svg?component"
import { gunzipByteStream } from "@/utils/compression"
import { base64ToByteStream, byteStreamToUTF8 } from "@/utils/encode"
import { omit } from "@/utils/object"

import type { SampledEvent } from "@/types/types"

interface Props {
  event: SampledEvent
  downloadFilename: string
}

const props = withDefaults(defineProps<Props>(), {})

const contentLength = computed(() => props.event.content.content_length)
const eventType = computed<string>(() => props.event.meta.event_type)
const isCompressed = computed(() => !!props.event.content.content_encoding)
const preview = computed(() =>
  isCompressed.value ? "Event body too large to preview..." : bodyWithoutMeta()
)

const downloaded = ref(false)

const { copy, copied } = useClipboard({})

function bodyWithoutMeta() {
  const body = JSON.parse(_decodeSimpleEvent())
  return JSON.stringify(omit(body, "_meta"))
}

function copyEvent() {
  copy(_decodeSimpleEvent())
}

// setup and perform download
async function downloadEvent() {
  downloaded.value = true
  const link = document.createElement("a")
  link.href = URL.createObjectURL(await _eventBodyBlob())
  link.download = props.downloadFilename
  link.click()
  URL.revokeObjectURL(link.href)
}

async function _decodeCompressedEvent() {
  const stream = await base64ToByteStream(props.event.content.body)
  if (!stream) throw new Error("Invalid input for conversion to byte stream")
  const decompressedStream = gunzipByteStream(stream)
  return byteStreamToUTF8(decompressedStream)
}

function _decodeSimpleEvent() {
  return atob(props.event.content.body) // body is passed base64 encoded
}

// Prepare a Blob of the event body
async function _eventBodyBlob(): Promise<Blob> {
  const type = "application/json"
  if (isCompressed.value) {
    return new Blob([await _decodeCompressedEvent()], { type })
  } else {
    return new Blob([_decodeSimpleEvent()], { type })
  }
}
</script>

<template>
  <div
    class="flex items-center space-x-5 bg-white px-4 py-2 text-xs border-b border-slate-100 last:border-0"
  >
    <div
      :title="eventType"
      class="text-xxs uppercase cursor-default font-bold border px-2 py-1"
    >
      {{ eventType.charAt(0) }}
    </div>
    <div class="grow whitespace-nowrap overflow-hidden text-ellipsis">
      {{ preview }}
    </div>
    <div>{{ contentLength }}</div>
    <div class="flex gap-x-1">
      <VButton
        color="secondary"
        size="small"
        :disabled="isCompressed"
        icon
        title="Copy to clipboard"
        @click="copyEvent"
      >
        <CopyIcon v-if="!copied" class="w-4 h-4" />
        <CheckIcon v-else class="w-4 h-4 text-emerald-500" />
      </VButton>
      <VButton
        color="secondary"
        size="small"
        icon
        title="Download"
        @click="downloadEvent"
      >
        <DownloadIcon v-if="!downloaded" class="w-4 h-4" />
        <CheckIcon v-else class="w-4 h-4 text-emerald-500" />
      </VButton>
    </div>
  </div>
</template>
