import type {
  MetricInstance,
  SourceType,
  TelemetryMetricType,
} from "@/types/types"

// given a list of metric instances, return the latest
export function getLatestMetricInstance(
  metrics: MetricInstance[]
): MetricInstance | undefined {
  // make this smarter?
  return metrics.at(-1)
}

// ex: getMeasurement(metricInstance, "events") #=> value or 0 if not present
export function getMeasurement(
  metricInstance: Ref<MetricInstance | undefined> | MetricInstance | undefined,
  type: string
) {
  const measurement = unref(metricInstance)?.metrics.find(
    (m) => m.name === type
  )
  return measurement?.value || 0
}

const TELEMETRY_METRICS: { [K in TelemetryMetricType]: string } = {
  logs: "logs",
  metrics: "metric_series",
  traces: "trace_spans",
}

// ex: getTelemetryMetric(metricInstance, "logs", "in") #=> value or 0 if not present
export function getTelemetryMetric(
  metricInstance: Ref<MetricInstance | undefined> | MetricInstance | undefined,
  type: TelemetryMetricType,
  direction: "in" | "out"
): number {
  const teleMetrics = unref(metricInstance)?.metrics.find(
    (m) => m.name === `telemetry_${direction}`
  )
  if (!teleMetrics) return 0
  const metric = teleMetrics.typed_values?.find(
    (m) => m.type === TELEMETRY_METRICS[type]
  )
  return metric?.value || 0
}

// which telemetry metrics should we show for a given source?
export function telemetryMetricTypesForSource(
  sourceType: SourceType | undefined
): TelemetryMetricType[] {
  if (!sourceType) return []
  if (["datadog_agent", "open_telemetry"].includes(sourceType)) {
    return ["logs", "metrics", "traces"]
  }
  if (sourceType === "elastic") return ["logs"]
  return []
}
