const dateStringRegexp = /^\d{4}-\d{1,2}-\d{1,2}$/
const isoStringRegexp = /^\d{4}-\d{1,2}-\d{1,2}T\d{2}:\d{2}:\d{2}/

// provide a datestring object and get back the related Date object
// accepts Date objects as well so you don't have to care what you are
// passing in when things are ambiguous
export const toDate = function (
  dateOrDateString: Date | number | string
): Date {
  if (dateOrDateString instanceof Date) return dateOrDateString
  if (typeof dateOrDateString === "number") return new Date(dateOrDateString)
  if (isoStringRegexp.test(dateOrDateString)) return new Date(dateOrDateString)
  if (dateStringRegexp.test(dateOrDateString)) {
    const [year, month, day] = dateOrDateString.split("-")
    return new Date(Number(year), Number(month) - 1, Number(day))
  }
  throw new Error("Unknown type!")
}

// given an array of dates, datestrings or timestamps, return the latest
export function latest(
  datesOrDateStrings: Array<Date | number | string>
): Date {
  const timestamps = datesOrDateStrings.map((d) => toDate(d).getTime())
  const max = Math.max(...timestamps)
  return toDate(max)
}
