// helpers for managing strings

const QUOTE_CHARACTERS = "\"'"

export const camelCase = function (value: unknown): string {
  if (!value) return ""
  const valstr = String(value)
  const chunks = valstr.split("_").map((v) => capitalize(v))
  return chunks.join("")
}

export const capitalize = function (value: unknown): string {
  if (!value) return ""
  const valstr = String(value)
  return valstr.charAt(0).toUpperCase() + valstr.slice(1)
}

export const isQuotedString = function (value: unknown): value is string {
  if (typeof value !== "string") return false
  const localValue = value.trim() // allow for padding
  const firstChar = localValue.substring(0, 1)
  if (QUOTE_CHARACTERS.includes(firstChar)) {
    return localValue.slice(-1) === firstChar
  }
  return false
}

// remove character a given <index>
export const removeCharacter = function (str: string, index: number): string {
  return str.slice(0, index) + str.slice(index + 1)
}

// strip outermost set of quotes from string, maintaining whitespace padding
export const removeQuotes = function (str: string): string {
  const firstCharIndex = str.search(/\S/) // search past leading whitespace
  const firstChar = firstCharIndex > -1 ? str.substring(firstCharIndex, 1) : ""
  if (!QUOTE_CHARACTERS.includes(firstChar)) return str // don't process

  const lastChar = str.trim().slice(-1)
  if (lastChar !== firstChar) return str // last char is not a closing quote

  const lastCharIndex = str.lastIndexOf(lastChar)
  return removeCharacter(removeCharacter(str, lastCharIndex), firstCharIndex)
}

// wrap string in double quotes
export const quoteString = function (str: string): string {
  const quote = QUOTE_CHARACTERS.substring(0, 1)
  return quote + str + quote
}
