<script setup lang="ts">
import {
  Dialog,
  DialogPanel,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue"

interface Props {
  display?: boolean
  position?: "left" | "right" | "center"
  size?: "medium" | "full"
  width?: "normal" | "3/5"
}

const props = withDefaults(defineProps<Props>(), {
  display: true,
  position: "center",
  size: "medium",
  width: "normal",
})

const emit = defineEmits(["close"])

const positionClasses: { [K in NonNullable<Props["position"]>]: string } = {
  left: "justify-start",
  right: "justify-end",
  center: "justify-center",
}

const sizeClasses: { [K in NonNullable<Props["size"]>]: string } = {
  medium: "", // let content decide height
  full: "h-[calc(100vh-32px)] flex flex-col", // full viewport height w 16px y-padding
}

const widthClasses: { [K in NonNullable<Props["width"]>]: string } = {
  normal: "", // let content decide
  "3/5": "w-3/5",
}

const dialogPanelClasses = computed(() => {
  return sizeClasses[props.size]
})

const transitionWrapperClasses = computed(() => {
  return widthClasses[props.width]
})

const wrapperClasses = computed(() => {
  return positionClasses[props.position]
})

function close() {
  emit("close")
}
</script>

<template>
  <TransitionRoot :show="props.display">
    <Dialog as="div" class="relative z-10" @close="close">
      <TransitionChild
        enter="ease-out duration-300"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="ease-in duration-200"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <!-- semi-transparent background behind the modal -->
        <div
          class="fixed inset-0 bg-slate-900 bg-opacity-60 transition-opacity"
        />
      </TransitionChild>

      <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div
          class="flex min-h-full items-center p-4 text-center"
          :class="wrapperClasses"
        >
          <TransitionChild
            enter="ease-out duration-300"
            enter-from="opacity-0 translate-y-4"
            enter-to="opacity-100 translate-y-0"
            leave="ease-in duration-200"
            leave-from="opacity-100 translate-y-0"
            leave-to="opacity-0 translate-y-4"
            :class="transitionWrapperClasses"
          >
            <DialogPanel
              class="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all"
              :class="dialogPanelClasses"
            >
              <slot></slot>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
