import type { MetricInstance } from "@/types/types"

// which resources we want the socket to subscribe to
interface ResourceSubscriptions {
  destinations?: string[]
  sources?: string[]
  streams?: string[]
}

// how the socket wants to receive subscriptions
interface SocketResourceSubscription {
  type: "destination" | "source" | "stream"
  id: string
}

// create subscription payload for target resources and send
// to update subscription for the socket
function subscribeToResources(
  metricsSocket: WebSocket,
  subscriptions: ResourceSubscriptions
) {
  const resources: SocketResourceSubscription[] = []
  if (subscriptions.destinations) {
    subscriptions.destinations.forEach((destId) =>
      resources.push({ type: "destination", id: destId })
    )
  }
  if (subscriptions.sources) {
    subscriptions.sources.forEach((sourceId) =>
      resources.push({ type: "source", id: sourceId })
    )
  }
  if (subscriptions.streams) {
    subscriptions.streams.forEach((streamId) =>
      resources.push({ type: "stream", id: streamId })
    )
  }
  const subscribeMsg = { subscribe: resources }
  metricsSocket.send(JSON.stringify(subscribeMsg))
}

// set up and return a metrics websocket subscribed to <subscriptions>,
// will pass new metrics to <metricCallback>.
//
// make sure to call .close() on the returned socket to clean it up
// when you are done using it
export function useResourceMetrics(
  subscriptions: ResourceSubscriptions,
  metricCallback: (metricInstances: MetricInstance[]) => void
) {
  const socket = useWebSocket("/metrics")

  socket.onmessage = (event: MessageEvent) => {
    const message = JSON.parse(event.data)
    if (message.status === "ready") {
      subscribeToResources(socket, subscriptions)
    } else if (message.data) {
      // receiving metric data, hand to callback
      const data = message.data as MetricInstance[]
      metricCallback(data)
    }
  }

  return socket
}
