// accept a local base64 encoded string and decode it into a ReadableStream of bytes
export async function base64ToByteStream(
  base64: string
): Promise<ReadableStream<Uint8Array> | null> {
  // prefix as a data uri so we can use fetch
  const dataStream = "data:application/octet-stream;base64," + base64
  const result = await fetch(dataStream)
  return result.body
}

// read contents of stream, decoding to a single utf8 string
export async function byteStreamToUTF8(
  byteStream: ReadableStream<Uint8Array>
): Promise<string> {
  let result = ""
  const utf8decoder = new TextDecoder()
  const reader = byteStream.getReader()
  // TODO: improve error handling
  while (true) {
    const { done, value } = await reader.read()
    result += utf8decoder.decode(value)
    if (done) break
  }
  return result
}
