// can value be cast to a number? either already a number or a valid number string
export function isNumeric(value: unknown): value is number {
  if (typeof value === "number") return true
  if (typeof value === "string") {
    return value !== "" && !isNaN(Number(value)) && !isNaN(parseFloat(value))
  }
  return false
}

// given a raw number, scale and return appropriate abbreviation
// ex: 21231 => [21.231, "k"]
export function numAbbreviation(
  num: number,
  base = 1000,
  units?: string[]
): [number, string] {
  const _units = ["", "k", "M", "B", "T", "P", "E", "Z", "Y"]
  units ||= _units

  if (num < 1) return [num, units[0]]

  const i = Math.floor(Math.log(num) / Math.log(base))
  const value = num / Math.pow(base, i)
  return [value, units[i]]
}

// takes a number and returns a formatted decimal, doing a best effort
// to meet criteria:
//   - goal number of significant digits (if possible)
//   - no trailing zeros in decimal digits
export function smartDecimal(num: number, goalDigits: number): string {
  const integerDigits = num.toFixed()
  const remainingDigits = goalDigits - integerDigits.length
  if (remainingDigits < 1) return integerDigits
  // trim trailing zeros by converting to float and back to string
  const formatted = `${Number(num.toFixed(remainingDigits))}`
  if (formatted === "0" && num > 0) {
    // number is positive but tiny, return '< 0.001', etc.
    return `< ${1 / Math.pow(10, goalDigits - 1)}`
  }
  return formatted
}
