import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  viewBox: "0 0 20 20"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "#000",
      "fill-rule": "evenodd",
      d: "M3 2c-.524 0-1 .425-1 .948V13c0 .524.476 1 1 1h3v3.052c0 .524.476.948 1 .948h10c.524 0 1-.476 1-1V7c0-.524-.476-1-1-1h-3V2.948C14 2.425 13.524 2 13 2zm1 10h2V7c0-.524.476-1 1-1h5V4H4zm4 4h8V8H8z",
      "clip-rule": "evenodd"
    }, null, -1)
  ])))
}
export default { render: render }