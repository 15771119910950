import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  viewBox: "0 0 20 20"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "#000",
      d: "M9.231 13.706a.983.983 0 0 0 1.403 0l3.507-3.548a1.01 1.01 0 0 0 0-1.42.983.983 0 0 0-1.402 0l-1.765 1.785v-6.52A1 1 0 0 0 9.982 3a1 1 0 0 0-.992 1.004l.001 6.62-1.865-1.886a.983.983 0 0 0-1.403 0 1.01 1.01 0 0 0 0 1.42z"
    }, null, -1),
    _createElementVNode("path", {
      fill: "#000",
      d: "M4 12a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-3a1 1 0 1 0-2 0v2H5v-2a1 1 0 0 0-1-1"
    }, null, -1)
  ])))
}
export default { render: render }