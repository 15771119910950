<script setup lang="ts">
import BaseModal from "@/components/BaseModal.vue"
import EventSamplerListItem from "@/components/EventSamplerListItem.vue"
import CloseIcon from "@/assets/icons/close.svg?component"
import LoaderIcon from "@/assets/icons/loader.svg?component"
import { useDestinationStore } from "@/stores/destination"
import { useSourceStore } from "@/stores/source"
import { useStreamStore } from "@/stores/stream"

import type { SampledEvent } from "@/types/types"

type ResourceType = "source" | "stream" | "destination"

interface EventSamplerSubscription {
  type: ResourceType
  id: string
  location?: string
}

interface Props {
  display: boolean
  resourceId?: string
  resourceType?: ResourceType
}

const props = withDefaults(defineProps<Props>(), {
  display: false,
  resourceId: undefined,
  resourceType: "source",
})

const emit = defineEmits(["close"])

const RESOURCE_TYPES = [
  { id: "source", name: "Source" },
  { id: "stream", name: "Stream" },
  { id: "destination", name: "Destination" },
]

const STREAM_SAMPLE_LOCATIONS = [
  { id: "in", name: "Input" },
  { id: "out", name: "Output" },
]

const events = ref<SampledEvent[]>([])
const sampling = ref(false)
const samplingSocket = ref<WebSocket | undefined>()
const selectedResource = ref(props.resourceId)
const selectedResourceType = ref(props.resourceType)
const selectedSampleLocation = ref("in")

const downloadFilename = computed(() => {
  if (selectedResourceType.value === "stream") {
    return `stream-${selectedResource.value}-${selectedSampleLocation.value}.json`
  } else {
    return `${selectedResourceType.value}-${selectedResource.value}.json`
  }
})

const isStream = computed(() => selectedResourceType.value === "stream")
const resourceSelectGrouping = computed(() =>
  isStream.value ? "left" : undefined
)

const resources = computed(() => {
  switch (selectedResourceType.value) {
    case "destination":
      return useDestinationStore().destinations
    case "source":
      return useSourceStore().sources
    case "stream":
      return useStreamStore().streams.filter(
        (stream) => stream.mode === "active"
      )
    default:
      throw new Error("Unknown resource type")
  }
})

watch(
  selectedResourceType,
  (newValue) => {
    // ensure we load all streams for select list
    if (newValue === "stream") useRefreshStreams()
  },
  { immediate: true }
)

function close() {
  emit("close")
  _resetState()
}

function startSampling() {
  events.value = []
  sampling.value = true

  const socket = useWebSocket("/event_capture")
  samplingSocket.value = socket

  socket.onmessage = (event: MessageEvent) => {
    const message = JSON.parse(event.data)
    if (message.status === "ready") {
      _subscribeResource(socket)
    } else if (message.event) {
      // receiving data
      if (!sampling.value) return _closeSocket() // user has stopped sampling, abort
      events.value.push(message.event as SampledEvent)
      if (events.value.length >= 10) stopSampling()
    }
  }
}

function stopSampling() {
  _closeSocket()
  sampling.value = false
}

function _closeSocket() {
  if (!samplingSocket.value) return
  samplingSocket.value.onmessage = null // remove handler
  samplingSocket.value.close()
  samplingSocket.value = undefined
}

function _resetState() {
  // clean up so we are in the proper state if re-opened
  stopSampling() // stop sampling if running
  events.value = []
  // user may have selected a different resource
  selectedResource.value = props.resourceId
  selectedResourceType.value = props.resourceType
}

function _subscribeResource(socket: WebSocket) {
  if (!selectedResource.value) return
  const subscription: EventSamplerSubscription = {
    type: selectedResourceType.value,
    id: selectedResource.value,
  }
  if (isStream.value) {
    subscription.location = selectedSampleLocation.value
  }
  socket.send(JSON.stringify({ subscribe: subscription }))
}
</script>

<template>
  <BaseModal
    :display="display"
    position="right"
    size="full"
    width="3/5"
    @close="close"
  >
    <!-- header -->
    <div class="flex justify-between items-center px-4 pt-4 pb-3 bg-white">
      <div class="text">Event Sampler</div>
      <div class="flex">
        <VButton color="secondary" icon>
          <CloseIcon class="w-5 h-5" @click="close" />
        </VButton>
      </div>
    </div>
    <!-- toolbar -->
    <div
      class="flex justify-between items-center space-x-2 px-4 py-3 bg-slate-25 border-t border-b border-slate-100"
      :class="{
        'bg-violet-25': sampling,
        'border-violet-100': sampling,
      }"
    >
      <!-- selection controls -->
      <div v-if="!sampling" class="flex grow divide-x">
        <!-- hide for now, we will probably want this in some contexts later -->
        <VSelect
          v-if="false"
          v-model="selectedResourceType"
          :options="RESOURCE_TYPES"
          group-position="left"
          class="w-32"
        />
        <VSelect
          v-model="selectedResource"
          :options="resources"
          :group-position="resourceSelectGrouping"
          class="grow"
        />
        <VSelect
          v-if="isStream"
          v-model="selectedSampleLocation"
          :options="STREAM_SAMPLE_LOCATIONS"
          group-position="right"
          class="w-32"
        />
      </div>
      <!-- progress state-->
      <div
        v-if="sampling"
        class="w-full flex justify-between items-center text-sm text-violet-300 font-medium"
      >
        <div class="flex space-x-2 items-center">
          <div class="text-violet-500">
            <LoaderIcon class="w-4 h-4 animate-spin" />
          </div>
          <div class="flex-1">Collecting samples...</div>
        </div>
        <div>{{ events.length }} / 10</div>
      </div>
      <!-- sampling buttons -->
      <div class="w-32">
        <VButton v-if="!sampling" full-width @click="startSampling">
          Start Sampling
        </VButton>
        <VButton v-else color="secondary" full-width @click="stopSampling">
          Stop
        </VButton>
      </div>
    </div>
    <!-- content section -->
    <div class="grow flex flex-col p-4 bg-slate-50 border-slate-100">
      <div
        v-if="events.length"
        class="border border-slate-100 rounded overflow-hidden"
      >
        <EventSamplerListItem
          v-for="(event, index) in events"
          :key="index"
          :event="event"
          :download-filename="downloadFilename"
        />
      </div>
      <div
        v-else
        class="grow border-2 border-dashed border-slate-200 rounded-lg"
      ></div>
    </div>
  </BaseModal>
</template>
