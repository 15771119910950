<script setup lang="ts">
import { capitalize } from "@/utils/string"

interface Props {
  selected: string
  tabs: string[]
  disabledTabs?: string[]
}

const _selected = ref("")

const props = withDefaults(defineProps<Props>(), {
  tabs: () => [],
  disabledTabs: () => [],
})

const emit = defineEmits(["select"])

function classes(tab: string) {
  if (isDisabled(tab)) {
    return "border-transparent text-slate-300 !cursor-not-allowed"
  } else {
    return isSelected(tab)
      ? "border-indigo-500 text-indigo-600"
      : "border-transparent text-slate-500 hover:text-slate-700"
  }
}

function isSelected(tab: string): boolean {
  return tab === _selected.value
}

function isDisabled(tab: string): boolean {
  return props.disabledTabs.includes(tab)
}

function select(tab: string) {
  _selected.value = tab
  emit("select", tab)
}

function clickTab(tab: string) {
  if (!isDisabled(tab)) {
    select(tab)
  }
}

function toName(tab: string): string {
  const chunks = tab.split("_").map((c) => capitalize(c))
  return chunks.join(" ")
}

onMounted(() => (_selected.value = props.selected))
</script>

<template>
  <div class="">
    <div class="px-8">
      <nav class="max-w-9xl mx-auto flex space-x-8 bg-white" aria-label="Tabs">
        <span
          v-for="tab in props.tabs"
          :key="tab"
          class="whitespace-nowrap border-b-2 py-4 px-3 text-sm font-medium cursor-pointer select-none"
          :class="classes(tab)"
          :aria-current="isSelected(tab) ? 'page' : undefined"
          @click="clickTab(tab)"
        >
          {{ toName(tab) }}
        </span>
      </nav>
    </div>
  </div>
</template>
