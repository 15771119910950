import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  viewBox: "0 0 20 20"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "#000",
      "fill-rule": "evenodd",
      d: "M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0m-9 6a6 6 0 1 0 0-12 6 6 0 0 0 0 12",
      "clip-rule": "evenodd",
      opacity: ".1"
    }, null, -1),
    _createElementVNode("path", {
      fill: "#000",
      d: "m12.187 4.41.78-2.91a9.004 9.004 0 0 1 1.611 16.25l-1.5-2.598a6.002 6.002 0 0 0-.891-10.74Z",
      opacity: ".2"
    }, null, -1)
  ])))
}
export default { render: render }